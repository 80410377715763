import { px } from 'util/transformToPixels';

import { Box, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

const spaceFromSide = 30;

export const HeaderRoot = styled('header')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: `10px 0`,
  [theme.breakpoints.up('lg')]: {
    alignItems: 'flex-start',
    left: 0,
    margin: `10px ${px(spaceFromSide)}`,
    position: 'absolute',
    top: '37px',
    width: `calc(100% - ${px(spaceFromSide * 2)})`,
    zIndex: 0,
  },
}));

export const Actions = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& > .MuiButtonBase-root:not(:last-child)': {
    margin: theme.spacing(0, 2, 0, 0),
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'column-reverse',
    '& > .MuiButtonBase-root:not(:last-child)': {
      margin: theme.spacing(2, 0, 0),
    },
  },
}));

export const companyLogo: SxProps<Theme> = () => ({
  maxHeight: '100px',
  maxWidth: { xs: '130px', lg: '11%' },
});
