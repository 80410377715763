import React from 'react';
import { useTranslation } from 'react-i18next';

import { Hidden, Typography } from '@mui/material';
import { Container } from 'component/Container/Container';
import { HeaderPure } from 'component/Header/HeaderPure';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { PageContainer } from 'component/PageContainer/PageContainer';
import { CenteredContent } from 'component/CenteredContent/CenteredContent.s';

import { UnauthorizedIcon } from './UnauthorizedIcon';

type UnauthorizedPageProps = {
  companyLogoUrl?: string;
  companyName?: string;
};

export const UnauthorizedPage: React.VFC<UnauthorizedPageProps> = ({
  companyLogoUrl,
  companyName,
}) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_PAGES);

  return (
    <PageContainer
      headerElement={
        <>
          <Hidden lgDown>
            <HeaderPure companyLogoUrl={companyLogoUrl} companyName={companyName} />
          </Hidden>
          <Hidden lgUp>
            <Container>
              <HeaderPure companyLogoUrl={companyLogoUrl} companyName={companyName} />
            </Container>
          </Hidden>
        </>
      }
    >
      {/* TODO: Create a new component that is shared across pages */}
      <CenteredContent data-testid="unauthorizedPage">
        <UnauthorizedIcon />
        <Typography variant="h3" sx={{ marginBottom: '10px' }}>
          {t('UnauthorizedPage.title')}
        </Typography>
        <Typography>{t('UnauthorizedPage.description[1]')}</Typography>
        <Typography>{t('UnauthorizedPage.description[2]')}</Typography>
      </CenteredContent>
    </PageContainer>
  );
};
