import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Hidden, Icon, Link, Typography } from '@mui/material';
import { Container } from 'component/Container/Container';
import { HeaderPure } from 'component/Header/HeaderPure';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { PageContainer } from 'component/PageContainer/PageContainer';
import { CenteredContent } from 'component/CenteredContent/CenteredContent.s';

import { IconWrapper } from './CannotLoadSurveyPage.s';

const SUPPORT_EMAIL = 'support@workbuzz.com';
const SUPPORT_EMAIL_HREF = `mailto:${SUPPORT_EMAIL}`;

export const CannotLoadSurveyPage: React.VFC = () => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_PAGES);

  return (
    <PageContainer
      headerElement={
        <>
          <Hidden lgDown>
            <HeaderPure />
          </Hidden>
          <Hidden lgUp>
            <Container>
              <HeaderPure />
            </Container>
          </Hidden>
        </>
      }
    >
      {/* TODO: Create a new component that is shared across pages */}
      <CenteredContent data-testid="cannotLoadSurveyPage">
        <IconWrapper>
          <Icon>warning</Icon>
        </IconWrapper>
        <Typography variant="h3" sx={{ marginBottom: '10px' }}>
          {t('CannotLoadSurveyPage.title')}
        </Typography>
        <Typography>
          <Trans
            i18nKey={`${LangNamespaceEnum.SURVEY_UI_PAGES}:CannotLoadSurveyPage.description`}
            values={{ href: SUPPORT_EMAIL_HREF, text: SUPPORT_EMAIL }}
            components={{ a: <Link /> }}
          />
        </Typography>
      </CenteredContent>
    </PageContainer>
  );
};
