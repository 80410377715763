import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GOOGLE_ADVERTISING_PRODUCTS } from 'util/links';

import { Button, Box, Divider, Icon, Link, Grid, Typography } from '@mui/material';
import { Container } from 'component/Container/Container';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { ThemeContext } from 'context/ThemeContext';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

import { RootBox } from './CookieConsentPure.s';

export type CookieConsentPureProps = {
  onAccept: () => void;
  onReject: () => void;
};

export const CookieConsentPure: React.VFC<CookieConsentPureProps> = ({
  onAccept,
  onReject,
}: CookieConsentPureProps) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const { themeMode } = useContext(ThemeContext);
  const [showCookies, setShowCookies] = useState<boolean>(false);

  return (
    <RootBox>
      <Container>
        <Typography mb={2} variant="h6">
          {t('CookieConsent.title')}
        </Typography>
        <Typography variant="body2" mb={1}>
          {t('CookieConsent.description[1]')}
        </Typography>
        <Typography variant="body2" mb={1}>
          {t('CookieConsent.description[2]')}
        </Typography>
        <Typography variant="body2" mb={1}>
          {t('CookieConsent.description[3]')}
        </Typography>
        <Grid container alignItems="center" spacing={2} mt={0}>
          <Grid item>
            <Button
              color={themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? 'primary' : 'success'}
              endIcon={<Icon fontSize="large">done</Icon>}
              onClick={onAccept}
              size="small"
              variant={
                themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? 'outlined' : 'contained'
              }
            >
              {t('CookieConsent.acceptCookiesButton')}
            </Button>
          </Grid>
          <Grid item>
            <Button color="secondary" onClick={onReject} size="small" variant="outlined">
              {t('CookieConsent.rejectCookiesButton')}
            </Button>
          </Grid>
          <Grid item>
            <Link onClick={() => setShowCookies(!showCookies)} variant="body2">
              {showCookies
                ? t('CookieConsent.hideMessageButton')
                : t('CookieConsent.viewMessageButton')}
            </Link>
          </Grid>
        </Grid>
        {showCookies && (
          <Box mt={3}>
            <Divider />
            <Typography variant="h6" mt={3} mb={1}>
              {t('CookieConsent.panelTitle')}
            </Typography>
            <Typography variant="body2" mb={1}>
              {t('CookieConsent.panelDescription')}
            </Typography>
            <Link href={GOOGLE_ADVERTISING_PRODUCTS} target="_blank" variant="body2">
              {t('CookieConsent.panelLink')}
            </Link>
          </Box>
        )}
      </Container>
    </RootBox>
  );
};
