import React, { ErrorInfo } from 'react';

type ErrorBoundaryPageState =
  | { hasError: true; error: Error; info: ErrorInfo }
  | {
      hasError: false;
      error?: unknown;
      info?: unknown;
    };

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryPageState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(this.state.error, this.state.info);

    this.setState({
      hasError: true,
      error: error,
      info: info,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Oops, something went wrong :(</h1>
          <p>The error: {this.state.error.toString()}</p>
          <p>Where it occurred: {this.state.info.componentStack}</p>
        </>
      );
    }

    return this.props.children;
  }
}
