import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { AccessibilityToggle } from 'component/Toggles/Accessibility/AccessibilityToggle';
import { LanguageToggle } from 'component/Toggles/Language/LanguageToggle';
import { SaveAndContinueToggle } from 'component/Toggles/SaveAndContinue/SaveAndContinueToggle';
import workBuzzDesktopLogoUrl from 'app/public/workbuzz-logo-colour.svg';
import workBuzzDesktopLogoDarkUrl from 'app/public/workbuzz-logo-dark.svg';
import { ThemeContext } from 'context/ThemeContext';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';
import { selectIsMultiLanguageEnabled } from 'selector/metaSelector';

import { Actions, companyLogo, HeaderRoot } from './HeaderPure.s';

export type HeaderPureProps = {
  companyLogoUrl?: string;
  companyName?: string;
  hasSaveAndContinue?: boolean;
};

export const HeaderPure: React.VFC<HeaderPureProps> = ({
  companyLogoUrl,
  companyName,
  hasSaveAndContinue = false,
}) => {
  const { themeMode } = useContext(ThemeContext);
  const isMultiLanguageEnabled = useSelector(selectIsMultiLanguageEnabled);

  const workbuzzLogoUrl =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
      ? workBuzzDesktopLogoDarkUrl
      : workBuzzDesktopLogoUrl;

  return (
    <HeaderRoot>
      <Box
        alt={companyName ? companyName : 'WorkBuzz'}
        component="img"
        data-testid="companyLogo"
        src={companyLogoUrl ? companyLogoUrl : workbuzzLogoUrl}
        sx={companyLogo}
      />
      <Actions>
        {isMultiLanguageEnabled && <LanguageToggle />}
        {hasSaveAndContinue && <SaveAndContinueToggle data-testid="saveAndContinue" />}
        <AccessibilityToggle />
      </Actions>
    </HeaderRoot>
  );
};
